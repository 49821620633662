<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-header">
                <h3 class="text-primary" style="font-size: 120%;">
                    <span class="badge badge-light-primary mr-1" style="padding: 1rem 1rem;" role="button"
                        routerLink="/dashboard-seller/{{seller_id}}"><i data-feather="bar-chart-2"
                            class="font-medium-3"></i> Statistiques Vendeur: {{data && data.seller_name ? data.seller_name : ''}}</span>
                    <span class="badge badge-light-primary mr-1" style="padding: 1rem 1rem; border: 2px solid #2a0d49;"
                        role="button" routerLink="/seller_contact/{{seller_id}}"><i
                            data-feather="phone-outgoing" class="font-medium-3"></i> Historique des contacts</span>
                    <span class="badge badge-light-primary" style="padding: 1rem 1rem" role="button"
                        routerLink="/seller_history/{{seller_id}}"><i data-feather="list"
                            class="font-medium-3"></i> Historique changement de statut</span>
                </h3>
                <div class="card-action">
                    <ng2-flatpickr [config]="globalDateOptions" name="globalDateRange" [(ngModel)]="period"
                        (ngModelChange)="changePeriod()" class="mr-1"></ng2-flatpickr>
                </div>
            </div>
        </div>
        <div class="text-center" *ngIf="!loadedData">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="card" *ngIf="loadedData">
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="d-flex justify-content-between align-items-center m-1">
                        <label class="d-flex align-items-center">Afficher
                            <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                                <option value="5">5</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            entrées</label>
                    </div>
                </div>
                <div class="col-md-8 col-12 d-flex justify-content-md-end">
                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                        <div class="input-group" [formGroup]="searchForm">
                            <div class="input-group-prepend">
                                <ng-select formControlName="contact_statut" placeholder=" Statut contact ">
                                    <ng-option value="0">Injoignable</ng-option>
                                    <ng-option value="1">Converti</ng-option>
                                    <ng-option value="2">Centre envoyé</ng-option>
                                    <ng-option value="3">RIB envoyé</ng-option>
                                    <ng-option value="9">A rappeler</ng-option>
                                    <ng-option value="4">Réfléchi</ng-option>
                                    <ng-option value="5">Séance gratuite</ng-option>
                                    <ng-option value="10">Abonnement gratuit</ng-option>
                                    <ng-option value="6">Refus</ng-option>
                                    <ng-option value="7">Hors cible</ng-option>
                                    <ng-option value="8">Faux numéro</ng-option>
                                </ng-select>
                            </div>
                            <ng-select formControlName="customer_statut" placeholder=" Statut actuel ">
                                <ng-option value="0">Pas encore contacté</ng-option>
                                <ng-option value="1">Converti</ng-option>
                                <ng-option value="2">Centre envoyé</ng-option>
                                <ng-option value="3">RIB envoyé</ng-option>
                                <ng-option value="9">A rappeler</ng-option>
                                <ng-option value="4">Réfléchi</ng-option>
                                <ng-option value="5">Séance gratuite</ng-option>
                                <ng-option value="10">Abonnement gratuit</ng-option>
                                <ng-option value="6">Refus</ng-option>
                                <ng-option value="7">Hors cible</ng-option>
                                <ng-option value="8">Faux numéro</ng-option>
                            </ng-select>
                            <div class="input-group-append">
                                <button type="button" (click)="retrieveContacts()" class="btn btn-outline-primary"
                                    rippleEffect>
                                    <i data-feather="search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                        <label class="d-flex align-items-center"><input type="search" placeholder="recherche"
                                class="form-control ml-25" (keyup)="filterContacts($event)"
                                (search)="filterContacts($event)" /></label>
                    </div>
                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                        <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary mr-1" rippleEffect>
                            Exporter CSV</a>
                    </div>
                </div>
            </div>

            <!-- Contacts Datatable -->
            <ngx-datatable [rows]="contactRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="5"
                [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption">
                <ngx-datatable-column name="Date" [width]="90">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        {{row.date}}<br>
                        <div *ngIf="row.time_frame">{{row.time_frame}}</div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="L'heur" [width]="90">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <b>De </b>{{row.start_time}}<b><br> à </b>{{row.end_time}}<br>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Durée" [width]="80">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        {{row.duration}} Min
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Commentaire" prop="comment" [width]="150"></ngx-datatable-column>
                <ngx-datatable-column name="Client" [width]="100">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        {{row.customer.first_name}} {{row.customer.last_name}}<br>
                        {{row.customer.phone}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Statut contact" [width]="100">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <span class="bullet bullet-sm bullet-{{ row.reachability ? 'success':'danger' }}"></span>
                        <span
                            class="badge badge-pill badge-light-{{ row.statut == 0 ? 'secondary' : row.statut == 1 ? 'primary' : row.statut == 2 ? 'warning' : row.statut == 3 ? 'success': row.statut == 4 ? 'warning': row.statut == 5 ? 'warning': row.statut == 9 ? 'warning': row.statut == 10 ? 'warning':'danger'}}">
                            {{ row.statut == 0 ? 'Injoignable' : row.statut == 1 ?
                            'Converti' :
                            row.statut == 2 ? 'Centre envoyé' : row.statut == 3 ? 'RIB envoyé' :
                            row.statut == 4
                            ? 'Réflichi': row.statut == 5 ? 'Séance gratuite': row.statut == 6 ? 'Refus':
                            row.statut == 9 ? 'A rapeller': row.statut == 10 ? 'Abonnement gratuit':'Hors
                            cible'}}
                        </span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Statut actuel" [width]="100">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <span
                            class="badge badge-pill badge-light-{{ row.customer.statut == 0 ? 'secondary' : row.customer.statut == 1 ? 'primary' : row.customer.statut == 2 ? 'warning' : row.customer.statut == 3 ? 'success': row.customer.statut == 4 ? 'warning': row.customer.statut == 5 ? 'warning': row.customer.statut == 9 ? 'warning': row.customer.statut == 10 ? 'warning':'danger'}}">
                            {{ row.customer.statut == 0 ? 'Injoignable' : row.customer.statut == 1 ?
                            'Converti' :
                            row.customer.statut == 2 ? 'Centre envoyé' : row.customer.statut == 3 ? 'RIB envoyé' :
                            row.customer.statut == 4
                            ? 'Réflichi': row.customer.statut == 5 ? 'Séance gratuite': row.customer.statut == 6 ?
                            'Refus':
                            row.customer.statut == 9 ? 'A rapeller': row.customer.statut == 10 ? 'Abonnement
                            gratuit':'Hors
                            cible'}}
                        </span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="d-flex align-items-center">
                            <a routerLink="/customer/{{row.customer.id}}/detail"
                                class="btn btn-raised btn-outline-primary btn-sm mr-1" rippleEffect>
                                <i data-feather="file-text"></i>
                            </a>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
            <!--/ Contacts Datatable -->
        </div>
    </div>
</div>