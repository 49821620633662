import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { StatisticService } from 'app/service/statistic/statistic.service';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  public period = []
  public global_period = []
  public salesFigures

  constructor(private _authenticationService: AuthenticationService, private statisticService: StatisticService, private datepipe: DatePipe) { }

  public globalDateOptions = {
    altInput: true,
    mode: 'range',
    defaultDate: [],
    maxDate: null,
    altFormat: 'Y-n-j',
  };

  getSalesFigures(){
    this.statisticService.salesFigures(this.period[0], this.period[1], null)
    .subscribe({
      next: (data) => {
        this.salesFigures = data;
      },
      error: (e) => console.error(e)
    });
  }

  ngOnInit(): void {
    if(this._authenticationService.currentUserValue){
      this._authenticationService.redirectUser(this._authenticationService.currentUserValue)
    }
    var date = new Date()
    var firstDate = new Date(new Date().setDate(date.getDate() - 30))
    var lastDate = new Date(new Date().setDate(date.getDate() + 1))
    this.globalDateOptions.maxDate = new Date(new Date().setDate(date.getDate() + 1))

    this.period = [this.datepipe.transform(firstDate, 'yyyy-MM-dd'), this.datepipe.transform(lastDate, 'yyyy-MM-dd')]
    this.changePeriod()
  }

  changePeriod() {
    if (this.period[1]) {
      this.getSalesFigures();

      this.global_period = [this.datepipe.transform(this.period[0], 'yyyy-MM-dd'), this.datepipe.transform(this.period[1], 'yyyy-MM-dd')]
      this.globalDateOptions.defaultDate = this.global_period
    }
  }

}